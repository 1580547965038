/* Loading.css */
.loader-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  height: 100%;
  color: black;
  background-color: aliceblue;
  position: absolute;
  width: 100vw;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 100px;
  --b: 16px; 
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%,#f03355) content-box;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 20deg,#0000 21deg 36deg),
    radial-gradient(farthest-side,#0000 calc(100% - var(--b) - 1px),#000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
          mask-composite: intersect;
  animation:l4 1s infinite steps(10);
}
@keyframes l4 {to{transform: rotate(1turn)}}

