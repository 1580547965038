/* body CSS */
/* =Structure
---------------------------*/
body {
  font-family: Montserrat, sans-serif;
  background-color: #fff;
  margin: 0;
}

#data-list {
  padding: 15vw;
  padding-top: 5vw !important;
  font-size: 20px;
  line-height: 30px;
}

.site {
  background-color: white;
  /* max-width: 1000px; */
  /* margin: auto; */
  overflow: hidden;
}

/* =Elements
  ---------------------------*/
img {
  max-width: 100%;
  height: auto;
  width:50vw; 
  margin-bottom: 52px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 26px;
}
p {
  text-align: justify;
  margin-bottom: 26px;
  margin-left: 20px;
}
p:last-child {
  margin-bottom: 26px;
}

hr {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
  margin-bottom: 20px;
}
/* =Header
---------------------------*/
.site-header {
  padding: 40px;
  text-align: center;
}
.site-title {
  margin: 0;
}
@media (min-width: 800px) {
  .site-title {
    float: left;
  }
}

/* =Content
---------------------------*/
.content-wrapper {
  padding: 40px;
}

/* =Footer
---------------------------*/
.site-footer {
  background-color: #333;
  color: white;
  overflow: hidden;
  padding: 40px;
}

table {
  width: 100%;
  margin-bottom: 26px;
}

table td {
  padding-left: 5px;
}

table,
th,
td {
  border: 1px solid;
}

/* topnav CSS */
.topnav {
  overflow: hidden;
  background-color: transparent;
  height: auto;
}

.topnav a {
  float: left;
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1.7vh;
}

.active {
  background-color: rgba(143, 41, 45, 0.7);
  color: white;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 1.7vh;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: "Montserrat";
  margin: 0;
}

.carrot-icon {
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #8f292d;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {
    float: none;
  }
  .topnav.responsive .dropdown-content {
    position: relative;
  }
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.container {
  height: calc(100lvh - 50px);
  width: 100%;
  border: none;
}